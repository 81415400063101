<script setup lang="ts">
import config, { AppMode } from '@/config'
import type { SearchResultCar } from '@/models/SearchResults'
import { useIlsaStore } from '@/stores/ilsa'
import { computed } from 'vue'

const props = defineProps<{
  car: SearchResultCar
}>()

const ilsaStore = useIlsaStore()

const modelSlug = computed(() => props.car.general.model.normalized.key.replace('cupra/', ''))

const promoLabel = computed(() => {
  if (!props.car.sales_conditions.pricing.asking.before_discount?.amount) {
    return null
  }
  const discount =
    props.car.sales_conditions.pricing.asking.before_discount?.amount -
    props.car.sales_conditions.pricing.asking.amount

  const formattedDiscount = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    maximumFractionDigits: 0
  }).format(discount)
  return `${formattedDiscount} korting`
})
const promoLabelClasss = computed(() => null)
</script>

<template>
  <RouterLink
    :to="`/${modelSlug}/${car.identification.slug}/${car.id}`"
    class="bg-white text-cupragray hover:shadow-lg scale-100 motion-safe:hover:scale-[1.01] transition-all duration-250 focus:outline focus:outline-2 focus:outline-copper"
  >
    <div class="relative overflow-hidden p-6 h-full flex flex-col justify-between">
      <div>
        <div v-if="promoLabel" class="absolute right-0 top-0 h-16 w-16">
          <div
            class="absolute transform rotate-45 font-semibold py-1 right-[-40px] top-[40px] w-[200px] uppercase text-center"
            :class="promoLabelClasss ? promoLabelClasss : 'bg-copper text-white'"
          >
            {{ promoLabel }}
          </div>
        </div>
        <h2 class="text-2xl font-light text-center">
          {{ car.general.make.name }} {{ car.general.model.name }}
        </h2>
        <div class="font-bold text-center md:line-clamp-1">
          {{ car.general.type.name }}
        </div>
        <div class="text-center md:line-clamp-1">
          <template v-if="config.appMode === AppMode.Used"
            >{{ car.powertrain.engine.energy.type.category.display_value }} |
            {{ car.condition.odometer.formatted }} | {{ car.general.year }}</template
          >
          <template v-else>
            {{ car.body.colour.name }}
          </template>
        </div>
      </div>

      <img
        :srcset="car.images[0].srcset"
        :alt="`${car.general.make.name} ${car.general.model.name} ${car.general.type.name}`"
        class="my-6 aspect-[1.33333] object-contain bg-black"
        width="432"
        height="324"
        loading="lazy"
      />

      <div class="flex flex-col gap-3 items-center">
        <div
          v-if="car.sales_conditions.pricing.asking.before_discount !== null"
          class="flex gap-4 justify-center"
        >
          <h3 class="line-through opacity-60">
            {{ car.sales_conditions.pricing.asking.before_discount!.formatted }}
          </h3>
          <h3 class="font-bold">
            <span class="font-normal">Nu voor</span>
            {{ car.sales_conditions.pricing.asking.formatted }}
          </h3>
        </div>
        <div v-else class="flex gap-4 justify-center">
          <h3 class="font-bold">
            <span class="font-normal">Nu voor</span>
            {{ car.sales_conditions.pricing.asking.formatted }}
          </h3>
        </div>

        <button class="mt-2 border border-cupragray hover:bg-oxygen hover:text-black h-12 w-full">
          Bekijk deze auto &gt;
        </button>
        <label @click.stop>
          <input
            type="checkbox"
            :value="car.id"
            v-model="ilsaStore.compareVehicleIds"
            class="text-black focus:ring-0"
          />
          Vergelijk
        </label>
      </div>
    </div>
  </RouterLink>
</template>
